<template>
  <!--
    Placeholder-image (scalable svg) to be used when the real image is not available.
  -->
  <svg
    :height="height"
    :width="width"
    @click="buttonClick"
  >
    <rect
      :width="width"
      :height="height"
      style="fill:lightgray;"
      @click="buttonClick"
    />
    <text
      :x="width / 2"
      :y="height / 4"
      fill="white"
      text-anchor="middle"
      font-size="1em"
      alignment-baseline="central"
      @click="buttonClick"
    >{{ mediaPreview ? 'Data' : $t('preview') }}</text>
    <text
      :x="width / 2"
      :y="height / 2"
      fill="white"
      text-anchor="middle"
      font-size="1em"
      alignment-baseline="central"
      @click="buttonClick"
    >{{ $t('not') }}</text>
    <text
      :x="width / 2"
      :y="height / 4 * 3"
      fill="white"
      text-anchor="middle"
      font-size="1em"
      alignment-baseline="central"
      @click="buttonClick"
    >{{ $t('available') }}</text>
  </svg>
</template>
  
<script>
export default {
  name: 'ImagePlaceholderSvg',
  props: {
    height: {
      type: Number,
      required: false,
      default () {
        return 100;
      }
    },
    width: {
      type: Number,
      required: false,
      default () {
        return 100;
      }
    },
    mediaPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    buttonClick (event) {
      this.$emit('buttonClick', event);
    }
  }
}
</script>

<style scoped>
svg rect,
svg text {
  cursor: pointer;
}
.modal svg rect,
.modal svg text {
  cursor: default;
}
</style>
